import './style.css'
import './animation'
import * as THREE from 'three'
import Stats from 'three/examples/jsm/libs/stats.module'

import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'


const scene = new THREE.Scene()

// const cubeTextureLoader = new RGBELoader()
// const envMap = cubeTextureLoader.load('/env.hdr',() =>
// {
//   envMap.mapping = THREE.EquirectangularReflectionMapping
//   scene.background = envMap
//   scene.environment = envMap
// })

const pointLight = new THREE.PointLight(0xffffff, 1)
pointLight.position.set(-3,3,0)
scene.add(pointLight)

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)


gltfLoader.load('/mars.glb', (gltf) =>
{
    const model = gltf.scene
    model.receiveShadow = true

    model.position.set(0,-3,-6)
    model.rotation.set(0,0,0)
    model.scale.set(2,2,2)
    scene.add(model)

})

const objectsDistance = 10

const particlesCount = 1000
const positions = new Float32Array(particlesCount * 3)

for(let i = 0; i < particlesCount; i++)
{
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

const particlesMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
    size: 0.001
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
camera.position.set(0,0,0)

const renderer = new THREE.WebGLRenderer()
renderer.setSize(window.innerWidth, window.innerHeight)
document.body.appendChild(renderer.domElement)



window.addEventListener('resize', onWindowResize, false)
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
    render()
}


gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({
    scrub: 3,
    ease: 'none',
})
const sections = document.querySelectorAll('.scroll-container')
const tl = gsap.timeline()

tl.from(camera.position,{
    x: 0,
    y: 0,
    z: 0,
    duration: 1,
    ease: 'expo',
    pinType: 'transform',
    toggleActions: "play none none reset",
})

tl.to(camera.position,{
    x: -3,
    z: -2,
    delay: 1,
    scrollTrigger: {
        immediateRender: false,
        trigger: sections[0],
        toggleActions: "play none none reset",
    }
})

tl.to(camera.rotation,{
    x: 1,
    delay: 1,
    scrollTrigger: {
        immediateRender: false,
        trigger: sections[1],
        toggleActions: "play none none reset",
    }
})

tl.to(camera.position,{
    y: -3,
    delay: 1,
    scrollTrigger: {
        immediateRender: false,
        trigger: sections[2],
        toggleActions: "play none none reset",
    }
})


document.body.onscroll = () => {
    $(".eight h1").css("opacity", 1 - $(window).scrollTop() / 50)
    $(".container h1").css("opacity", 5 - $(window).scrollTop() / 100)
}

window.addEventListener('mousemove', onMouseMove, false);

function onMouseMove(event) {
    scene.position.x = (event.clientX - window.innerWidth / 2)/ Math.PI*.0001
}

const stats = Stats()
document.body.appendChild(stats.dom)

function animate() {
    requestAnimationFrame(animate)

    render()

    stats.update()
}

function render() {
    renderer.render(scene, camera)
}


animate()